

.xType > .is-revealed p {
    border-right: .15em solid orange;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
}
.xType > .is-revealed p:nth-child(1) {
    width:23em;

    -webkit-animation: type 2s steps(100, end);
    animation: type 2s steps(100, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.xType > .is-revealed p:nth-child(2) {
    width:23em;
    opacity: 0;
    -webkit-animation: type2 2s steps(100, end);
    animation: type2 2s steps(100, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.xType > .is-revealed p:nth-child(3) {
    width:20.6em;
    opacity: 0;
    -webkit-animation: type2 2s steps(100, end);
    animation: type2 2s steps(100, end);
    -webkit-animation-delay: 2s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}





