.item_x{
  border-radius: 15px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #eee;
  display: flex;
  height: 400px;
  justify-content: center;
  position: relative;
  width: 400px;
  img{
    border-radius: 15px;
    object-fit: cover;
    height: 400px;
    width: 400px;
  }
  &::before, &::after {
    background-image: linear-gradient(-180deg, #287199 0%, #5EA3DB 97%);
    border-radius: 15px;
    content: '';
    height: 100%;
    position: absolute;
    transition: all .2s;
    transition-delay: .2s;
    transform: translateY(0px) translateX(0px);
    width: 100%;
    z-index: -1;
  }
  &::after {
    background-image: linear-gradient(0deg, #FFCF70 0%, #FFAE3D 97%);
  }
  &:hover{
    animation-name: hoverPop;
    animation-duration: .4s;
    animation-fill-direction: forward;
    img{
      box-shadow: 2px 2px 4px 0px rgba(10,12,15,0.27);
      box-shadow: 0px 0px 30px 0px rgba(10,12,15,0.27);
    }
    &::before{
      animation: slick-hover-2 3s .4s linear infinite;
      transform: translateY(-20px) translateX(-20px);
    }
    &::after{
      animation: slick-hover 3s .4s linear infinite reverse;
      transform: translateY(20px) translateX(20px);
    }
  }
}

@keyframes hoverPop {
  0%, 100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(.90);
  }
}

@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px) }
  16.67% {
    transform: translateY(-13px) translateX(-9px) }
  33.33% {
    transform: translateY(4px) translateX(12px) }
  50.00% {
    transform: translateY(20px) translateX(-20px) }
  66.67% {
    transform: translateY(-3px) translateX(8px) }
  83.33% {
    transform: translateY(-15px) translateX(-11px) }
  100.00% {
    transform: translateY(20px) translateX(20px) }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px) }
  16.67% {
    transform: translateY(3px) translateX(-5px) }
  33.33% {
    transform: translateY(18px) translateX(-10px) }
  50.00% {
    transform: translateY(-20px) translateX(20px) }
  66.67% {
    transform: translateY(15px) translateX(-4px) }
  83.33% {
    transform: translateY(-1px) translateX(15px) }
  100.00% {
    transform: translateY(-20px) translateX(-20px) }
}