
/*BROWSER FIX- Safari: Does not support animating clipPath/Mask for SVG, so instead used css masking*/
svg#svg_notification {
    cursor: pointer;
    fill: rgb(255, 255, 255);
    height: 23px;
    width: 14px;
    overflow: visible;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

svg#svg_notification:hover  {
    transition: 1s;
    fill: #FFCF70;
}


