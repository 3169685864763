
.dropdown {

  position: absolute;
  width: 55px;
  border-radius: 5px;
  top: 30px;
}



.dropdown-header {
  font-size: 15px;
  //padding: 3px 5px 3px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  margin-bottom: -180px;
  padding: 2.5px;
  //display: none;
}

.dropdown-body.open {
  margin-left: 25px;
  display: block;
}

.dropdown-item {

  display: flex;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91A5BE;
  transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
  margin-right: 3px;
  opacity: 1;
}

.icon {
  font-size: 2px;
  color: #91A5BE;
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.flag-i {
  border-radius: 2px;
  width: 25px;
}