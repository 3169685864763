@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;1,500&display=swap");

$purple: #ffe39e;
$blue: #fffaf0;
$lightblue: #376b87;
$magenta: #376b87;

// This is an example of animations & svgs... I completely understand that the amount of absolute positioning in this file is insane... ;) <3

.arrow-container {

  font-size: calc(17px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-family: "DM Mono", monospace;
  font-weight: 300;
  //overflow: hidden;
  color: white;
  //text-align: center;

}

.main {
  //width: 100%;
  //height:  100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //position: absolute;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    padding: 2em;
    top: 40%;
    left: 9%;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
}
.mainOur {
  //width: 100%;
  //height:  100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //position: absolute;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    padding: 2em;
    top: 20%;
    left: -200px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
  .mainOur__calc {

    position: absolute;
    padding: 2em;
    top: 28%;
    left:-170px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }

}
.main_nextOur{
  //width: 100%;
  //height:  100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //position: absolute;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    padding: 2em;
    top: 40%;
    right: -200px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }


  #outlined-basic input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .mainOur__calcX{
    position: absolute;
    padding: 2em;
    top: 28%;
    right:-170px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
}
.main_part{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    padding: 2em;
    top: 40%;
    right: -200px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }


  #outlined-basic input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .mainOur__part{
    position: absolute;
    padding: 2em;
    top: 5%;
    right: -219px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
}
.main_part_sec{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    padding: 2em;
    top: 40%;
    right: -200px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }


  #outlined-basic input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .mainOur__part_sec {
    position: absolute;
    bottom: 12%;
    left: -157px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
}




.main_next {
  //width: 100%;
  //height:  100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //position: absolute;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    padding: 2em;
    top: 50%;
    right: 7%;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
}




.main_next_next {

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__text-wrapper {
    position: absolute;
    //padding: 2em;
    top:5%;
    left: -33%;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
  }
}

.arrow {
  //z-index: 1000;
  opacity: 0.5;
  position: absolute;

  &--top {
    top: 40px;
    left: -170px;
  }

  &--bottom {

    bottom: 2%;
    right: 1em;
  }
}

.circle {
  transform: translate(50%, -50%) rotate(0deg);
  transform-origin: center;

  &--ltblue {
    $circle-unit: 20em;
    height: $circle-unit;
    width: $circle-unit;
    border-radius: $circle-unit;
    background: linear-gradient($lightblue, $blue);
  }
}

.backdrop {

  position: absolute;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: pink;
}

.dotted-circle {
  //position: absolute;
  //overflow: inherit;
  top: 0;
  right: 0;
  opacity: 0.3;
  animation: rotation 38s linear infinite;
}
.dotted-circle-sun {

  animation: rotation 38s linear infinite;
}
.dotted-circleY {
  //position: absolute;
  //overflow: inherit;
  top: 0;
  right: 0;
  opacity: 0.3;
  animation: rotationY 38s linear infinite;
}

// animations
.draw-in {

  stroke-dasharray: 1000;
  stroke-dashoffset: 10;
  animation: draw 15s ease-in-out alternate infinite;
}

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}
.geo-arrow {
  fill: none;
  stroke: #013856;
  stroke-width: 1;
  stroke-miterlimit: 10
}
.item-to {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}

.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: ease;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}

.bounce-3 {
  animation-name: bounce-3;
  animation-timing-function: ease;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

// gradient fade

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotationY {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes gradient-fade {
  from {
    transform: translate(10%, -10%) rotate(0deg);
  }
  to {
    transform: translate(50%, -50%) rotate(360deg);
  }
}

@keyframes gradient-fade-alt {
  from {
    transform: translate(-20%, 20%) rotate(0deg);
  }
  to {
    transform: translate(-60%, 60%) rotate(360deg);
  }
}
