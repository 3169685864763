@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 1px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  //box-shadow: inset 0 0 0 1.5px $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border-radius: 7px;
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before { transition-delay: 0s, 0s, $duration; }

    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {

  @include btn-border-drawing(#e8e8e8, #FFC355, 1.5px, bottom, right);
}

//=== Button styling, semi-ignore
.btn {

  border-radius: 7px;
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 0.63rem 'Roboto Slab', sans-serif;
  padding: 1em 2em;
  letter-spacing: 0.05rem;

  //&:focus { outline: 2px dotted #55d7dc; }
}

//=== Pen styling, ignore
//body {
//  background: #1f1a25;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  min-height: 100vh;
//}