
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}


.site-header {
	position: absolute !important;
	top: 0;
	width: 100%;
	z-index: 10 !important;
	background: color-bg(header);

	+ .site-content {

		.section:first-of-type {
			padding-top: $header-height__mobile;
		}
	}

	.brand {
		margin-right: $header-nav--padding-h;
	}	
}

.site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
	height: $header-height__mobile;
}

.header-nav {
	flex-grow: 1;

	.header-nav-inner {
		display: flex;
		flex-grow: 1;
	}

    ul {
        display: flex;
        align-items: center;
		flex-grow: 1;
        white-space: nowrap;
		margin-bottom: 0;

		&:first-of-type {
			flex-wrap: wrap;
		}
    }

    li {

		+ .header-button {
			margin-left: $header-nav--padding-h;
		}
    }

    a:not(.button) {
		display: block;
        @include anchor-aspect(header);
		@include font-weight(header-link);
		text-transform: $link-header--transform;
        //padding: 0 $header-nav--padding-h;

		.invert-color & {
			@include anchor-aspect(header, inverse);
		}
    }

	a.button {
		margin-left: $header-nav--padding-h;
	}
}

.header-nav-center:first-of-type {
	flex-grow: 1;
	justify-content: flex-end;
}

.header-nav-right {
	justify-content: flex-end;

	+ .header-nav-right {
		flex-grow: 0;
	}
}

.header-nav-toggle {
	display: none;
}

@include media( '<=medium' ) {

	.animated {
		animation-duration: 1500ms;
		animation-fill-mode: both;
	}

	.animated.infinite {
		animation-iteration-count: infinite;
	}

	.animated.hinge {
		animation-duration: 2s;
	}

	.animated.flipOutX,
	.animated.flipOutY,
	.animated.bounceIn,
	.animated.bounceOut {
		animation-duration: .75s;
	}

	@keyframes flipInX {
		from {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
			transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
			-webkit-animation-timing-function: ease-in;
			animation-timing-function: ease-in;
			opacity: 0;
		}

		40% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
			transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
			-webkit-animation-timing-function: ease-in;
			animation-timing-function: ease-in;
		}

		60% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
			transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
			opacity: 1;
		}

		80% {
			-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
			transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
		}

		to {
			-webkit-transform: perspective(400px);
			transform: perspective(400px);
		}
	}
	.flipInX {
		-webkit-backface-visibility: visible !important;
		backface-visibility: visible !important;
		-webkit-animation-name: flipInX;
		animation-name: flipInX;
	}


	@keyframes fadeeIn {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, 100%, 0);
			transform: translate3d(0, 100%, 0);
		}

		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
	.fadeeIn {
		-webkit-animation-name: fadeeIn;
		animation-name: fadeeIn;
	}


	.sizeY {
		margin-left: 43%!important;
	}

	.pwSize {
		margin-left: -138px;
		margin-top: 79px;
	}
	.pwSize2 {
		margin-left: 22px;
		margin-top: 115px;
	}
	.solSys {
		width: 55%;
		margin-left: 22%;
	}
	.textStyle {
		margin-left: 84px;
	}

	.contPad {

	}

	.textSize  p {
		font-size: 13px!important;
		text-align: start;
		//padding-left: 10%;
	}

	.pwEnergy{
		margin-left: 50%!important;
		height: 168px;
		width: 168px;
	}

	.header-nav-toggle {
		display: block;

		// Header navigation when the hamburger is a previous sibling
		+ .header-nav {
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 9999;
			background: color-bg(menu-mobile);
			max-height: 0;
	        opacity: 0;
	        overflow: hidden;
	        transition: max-height .25s ease-in-out, opacity .15s;

			&.is-active {
				opacity: 1;
			}

			.header-nav-inner {
				flex-direction: column;
				padding: $header-nav--padding-v__mobile;
			}

			ul {
				display: block;
				text-align: center;

			    a:not(.button) {
					display: inline-flex;
			        @include anchor-aspect(header-mobile);
					padding-top: $header-nav--padding-v__mobile / 2;
					padding-bottom: $header-nav--padding-v__mobile / 2;
			    }
			}

			a.button {
				margin-left: 0;
				margin-top: $header-nav--padding-v__mobile / 2;
				margin-bottom: $header-nav--padding-v__mobile / 2;
			}
		}
	}
}

@include media( '>medium' ) {

	.site-header {

		+ .site-content {

			.section:first-of-type {
				padding-top: $header-height__desktop;
			}
		}
	}

	.site-header-inner {
		height: $header-height__desktop;
	}
}
