.xType3 > .is-revealed p {
    border-right: .15em solid orange;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
}
.xType3 > .is-revealed p:nth-child(1) {
    width:20em;

    -webkit-animation: type 2s steps(80, end);
    animation: type 2s steps(80, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.xType3 > .is-revealed p:nth-child(2) {
    width:20em;
    opacity: 0;
    -webkit-animation: type2 2s steps(80, end);
    animation: type2 2s steps(80, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.xType3 > .is-revealed p:nth-child(3) {
    width:24.6em;
    opacity: 0;
    -webkit-animation: type2 2s steps(80, end);
    animation: type2 2s steps(80, end);
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.xType3 > .is-revealed p:nth-child(4) {
    width:27.6em;
    opacity: 0;
    -webkit-animation: type2 2s steps(80, end);
    animation: type2 2s steps(80, end);
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.xType3 > .is-revealed p:nth-child(5) {
    width:20.0em;
    opacity: 0;
    -webkit-animation: type2 2s steps(80, end);
    animation: type2 2s steps(80, end);
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.xType3 > .is-revealed p:nth-child(6) {
    width:25.0em;
    opacity: 0;
    -webkit-animation: type2 2s steps(80, end);
    animation: type2 2s steps(80, end);
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.xType3 > .is-revealed p:nth-child(7) {
    width:27.0em;
    opacity: 0;
    -webkit-animation: type2 2s steps(80, end);
    animation: type2 2s steps(80, end);
    -webkit-animation-delay: 12s;
    animation-delay: 12s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}



