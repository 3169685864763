.features-tiles {

	.section-inner {
		padding-top: $features-tiles--padding-t__mobile;
		padding-bottom: $features-tiles--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $features-tiles-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $features-tiles-items--padding__mobile != null ) {
			margin-right: -($features-tiles-items--padding__mobile / 2);
			margin-left: -($features-tiles-items--padding__mobile / 2);
			margin-top: -($features-tiles-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($features-tiles-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($features-tiles-items--padding__mobile / 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $features-tiles-item--width;
				max-width: $features-tiles-item--width;
				@if ( $features-tiles-items--padding__mobile != null ) {
					padding-left: $features-tiles-items--padding__mobile / 2;
					padding-right: $features-tiles-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $features-tiles-item--width;
		max-width: $features-tiles-item--width;
		@if ( $features-tiles-items--padding__mobile != null ) {
			//padding: $features-tiles-items--padding__mobile / 2;
		}
	}

	.tiles-item-inner {
		padding-top: $features-tiles-item--inner-padding-v;
		padding-bottom: $features-tiles-item--inner-padding-v;
		padding-left: $features-tiles-item--inner-padding-h;
		padding-right: $features-tiles-item--inner-padding-h;
	}
}

@include media( '>medium' ) {

	.features-tiles {

		.section-inner {
			padding-top: $features-tiles--padding-t__desktop;
			padding-bottom: $features-tiles--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-tiles-header--padding__desktop;
		}

		@if ( $features-tiles-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($features-tiles-items--padding__desktop / 2);
				margin-left: -($features-tiles-items--padding__desktop / 2);
				margin-top: -($features-tiles-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($features-tiles-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($features-tiles-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						padding-left: $features-tiles-items--padding__desktop / 2;
						padding-right: $features-tiles-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				//padding: 0px 170px 38px 70px;
				//padding: $features-tiles-items--padding__desktop / 2;
			}
		}
	}
}
@include media( '<1100px' ) {



	.features-tiles {
		.tiles-item {
			//padding:0px 100px 38px 22px !important
			//padding: $features-tiles-items--padding__desktop / 2;
		}
	}

}

@include media( '<660px' ) {
	.sm-img {
		height: 300px!important;
		width: 300px!important;
	}

	.item_x {
		width: 300px!important;
		height: 300px!important;

	}
}
@include media( '<640px' ) {
	.dfs {
		display: block!important;
	}
	//.ash-g > div {
	//	margin: 0px 0 17px !important;
	//}

}

//@include media( '<640px' ) {
//	.dfs {
//		padding-top: 40px;
//		padding-bottom: 40px;
//		flex-direction: row;
//		flex-wrap: nowrap;
//		align-items: center;
//		justify-content: flex-start;
//	}
//
//}


@include media( '<520px' ) {
	.features-tiles-item-content  {
		p{
			width: revert!important;
			text-align: center!important;
		}

	}
	.features-tiles {
		.tiles-item {
			padding:50px 100px 38px 100px !important
			//padding: $features-tiles-items--padding__desktop / 2;
		}
	}

}

@include media('<1024px') {
	.scroll-downSocial {

		bottom: 33px!important;
		left: 5.5rem!important;
	}

	.typing-textH {
		bottom: 10px;
		left: 3.5rem;
	}

	.arrType {

		bottom: 15px;
	}
	.scroll-down {
		bottom: 41px;
		left: 85%;
	}
}

@include media('<1100px') {
	.nav-c > li{
		font-size: 1.7vw!important;
		font-weight: bold;
	}

	.story-c {
		width: 606px!important;
		bottom: 2px!important;
		right: -210px!important;
	}
	.size-c {
		font-size: 13px!important;
	}

}
@include media('<840px') {
	.c-hide {
		display: none!important;
	}
	.wide-h {
		width:100%;
		padding: 10px 12px 0 6px;
	}

	.wide-h > p {
		font-size: 2.6vw;
	}

	.ks-w {
		width:100%!important;
	}

	.text-div-c > p {
		font-size: 13px!important;
	}
}

@include media('>1228px') {
	.text-const {
		font-size: 16px!important;
	}
}
@include  media('<640px') {
	.marg-s > div >div{
		margin: 166px 0 17px;
	}
	.ml-sr {
		margin-top: 224px!important;
	}
	.i-ds > div {
		margin: 166px 0 17px!important;
	}
	.isp-s {
		margin-top: 3px;
	}

	.marg-sx > div > div {
		margin: 217px 0 17px
	;
	}
}

@include  media('<533px') {
	.nav-s-arm > li{
		font-weight: bold;
		text-align: center;
		line-height: 14px;
		font-size: 2.18vw  !important

	}
	.nav-s-rus > li{
		font-weight: bold;
		text-align: center;
		line-height: 14px;
		font-size: 2.9vw !important
	}
	.nav-s-eng > li{
		font-weight: bold;
		text-align: center;
		line-height: 14px;
		font-size: 3.8vw !important
	}
	.nav-s-eng > li:last-child {
		margin-top:-7px ;
	}
	.nav-s-rus > li:last-child {
		margin-top:-7px ;
	}

	.typing-textH-Our-mob {
		top: unset!important;
		left:unset!important;
		transform: unset!important;
	}

}

.fs-v  {
background-color: #FAFAFA
}



@include  media('<640px') {

	.d-nonem {
		display: none;
	}

	.d-m-co {
			padding: 5px 0 5px 10px;
			border-radius: 3px;
			top: 46px;
			margin-left: -11px;
	}

	.d-cars {
		margin-top: -6px;
		width: 100%;
	}

	.margin-w {
		margin-top: -240px!important;
		text-align: start!important;
	}
	.margin-w2 {
		text-align: start!important;
	}
	.margin-vi {
		margin: -192px 0px 0px 7px
	}
	.cont-c {
		margin:-192px 0px 0px -7px!important;
	}
	.wide-h > p {
		font-size: 3.6vw;
	}
	.mob-st {
		width: 240px;
		margin: 25px;
	}
	.wrapper-b > button {
		width: 90px;
		height: 30px;
		font-size: 8px;
		margin: 0 12px 0 0;
	}
	.m-auto {
		margin: auto;
	}

	.calc-m {
		flex-direction: column;
	}

	.ul-m {
		 width: 100vw!important;
		 margin-left: -32px!important;

	.m-display {
		height: 1220px!important;
	}
}

	.d-mob-s {
		height: 480px!important;
	}

	.ks-w-x {
		margin-top: -211px !important;
	}
}

@include  media('<=425px') {

	.wide-h > p {
		font-size: 3.9vw;
	}
	.cont-chs {
		height: fit-content!important;
	}
	.serv-fo {
		font-size: 26px;
	}
}

@include  media('<=666px') {
.n-flex {
    display: unset!important;
}
.logo-e {
    width: 30px!important;
}
.logo-w {
    left: -13px!important;
}

.text-div-c > p {
	margin-right: 15px;
	border: 2px solid #fff;
	padding-right: 15px;
}
}

@include  media('<=1100px') {

	.text-div-c > p {
		font-size: 1.3vw;
		font-weight: 500;
	}

}