$min-width: 120px;
$maincolor: linear-gradient(0deg, #FFCF70 0%, #FFAE3D 97%);
$secondarycolor: linear-gradient(
                -180deg
        , #287199 0%, #5EA3DB 1%);

@mixin transform($value){
    -webkit-transform: ($value);
    -moz-transform: ($value);
    -ms-transform: ($value);
    transform: ($value);
}
@mixin transition($value){
    -webkit-transition: ($value);
    -moz-transition: ($value);
    -ms-transition: ($value);
    transition: ($value);
}
li:not(:last-child) {
    margin-bottom: 0px !important;
    font-size: 12px;
}

#main > ul, #main > ol {
    margin-top: 0px!important;
    margin-bottom: 0;
}

#main{
    line-height: 0px!important;
    border-radius: 10px;
    position: fixed;
    left: 2%;
    top: -5%;
    z-index: 1000;
    list-style: none;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    padding: 0;
    margin: 50px auto;
    li{
        font-size: 0.7rem;
        //display: inline-block;
        position: relative;
        padding: 15px 20px;
        cursor: pointer;
        z-index: 5;

        //min-width: $min-width;
        //width: 211px;
    }

}


.menu {
    list-style: none;
    padding-left: 0;
}
.menu a {
    transition: 1s;
    color: rgb(1, 56, 86)!important;
    padding: 10px 10px;
    display: block;
    position: relative;
}
.menu li > a:not(:only-child) {
    padding-right: 20px;
}
.menu li > a:not(:only-child)::after {
    content: '+';
    transform: translateY(-50%);
    position: absolute;
    right: 5px;
    top: 50%;
}
@keyframes heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.heartBeat {
    -webkit-animation-name: heartBeat;
    animation-name: heartBeat;
    -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
    animation-duration: calc(var(--animate-duration) * 1.3);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
//
//-webkit-animation-duration: calc(1s * 1.3);
//animation-duration: calc(1s * 1.3);
//-webkit-animation-duration: calc(var(--animate-duration) * 1.3);
//animation-duration: calc(var(--animate-duration) * 1.3);
@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    40% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }

    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}
.swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}
.logoPage {
    animation-name: swing;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-delay: 1s;
    width: 60px;
}
.menu a:hover,
.menu li:hover > a {
    transition: 500ms;
    background-color: rgba(0,0,0,0.2);
    padding: 20px 0 20px 0;
    border-radius: 5px;
}
.menu ul {
    padding-left: 0;
    list-style: none;
}
.menu li {
    position: relative;
}
.menu li:hover > .sub-menu {
    display: block;
}
.menu .sub-menu {
    background-color: red;
    display: none;
}



.menu > li {
    display: inline-block;
}
//.menu li:hover > .sub-menu {
//    opacity: 1;
//    visibility: visible;
//    transform: scaleY(1);
//}
.menu .sub-menu {
    border-radius: 5px;
    min-width: 200px;
    max-width: 150px;
    display: block;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    transition: all 0.2s ease-in;
    transform: scaleY(.5);
    transform-origin: top;
    position: absolute;
    left: 0;
    top: 100%;
    background: whitesmoke;

}
.menu li li .sub-menu {
    top: 0;
    left: 100%;
}
.menu li li .sub-menu {
    top: 0;
    left: 100%;
}

.i-calc {
    width: 22px;
    margin:-6px 0 -5px -30px!important;
    display: inherit;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.fadeInLeft {
    z-index: 1000!important;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}
@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}
@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-300%, 0, 0);
        transform: translate3d(-300%, 0, 0);
    }
}
.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}


