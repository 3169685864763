.testimonial {

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $testimonial-items--padding__mobile != null ) {
			margin-right: -($testimonial-items--padding__mobile / 2);
			margin-left: -($testimonial-items--padding__mobile / 2);
			margin-top: -($testimonial-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile / 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;
				@if ( $testimonial-items--padding__mobile != null ) {
					padding-left: $testimonial-items--padding__mobile / 2;
					padding-right: $testimonial-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $testimonial-item--width;
		max-width: $testimonial-item--width;
		@if ( $testimonial-items--padding__mobile != null ) {
			padding: $testimonial-items--padding__mobile / 2;
		}
	}

	.tiles-item-inner {
		padding-top: $testimonial-item--inner-padding-v;
		padding-bottom: $testimonial-item--inner-padding-v;
		padding-left: $testimonial-item--inner-padding-h;
		padding-right: $testimonial-item--inner-padding-h;
	}
}

.testimonial-item-content {
	flex-grow: 1;
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media( '>medium' ) {

	.testimonial {

		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}
		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}
		@if ( $testimonial-items--padding__desktop != null ) {
			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop / 2);
				margin-left: -($testimonial-items--padding__desktop / 2);
				margin-top: -($testimonial-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop / 2;
						padding-right: $testimonial-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop / 2;
			}
		}
	}
}

@include  media('<640px') {

	.m-display {
		height: 1140px!important;
	}
	.m-gxt {
		padding-top: 0!important;
		display: flex!important;
		flex-direction: row-reverse;
	}
}
@include  media('<=760px') {
	.klp-oop {
		height: unset!important;
	}
}


@include  media('<=1066px') {
	.m-gx {padding-top: 16px}
}

@include  media('<532px') {
	.m-st {line-height: 20px;}
	.m-gx {padding-top: 16px!important;}
	.m-ga {
		position: absolute;
		margin-top: -18px;
	}
}

.space-arr {
	justify-content: space-evenly!important;
}

.p-huw-t {
	background-color: rgba(255,127,111,0.05)!important;
}
.p-glob-t {
	background-color: rgba(129,98,81,0.05) !important;
}

.p-huw-cont {
	height: 114px;
	width: fit-content;
	border-radius: 15px 15px 0 0!important;
	background-color: #ffff;
	padding: 15px;
}
.p-glob-cont {
	width: 100%;
	border-radius: 15px 15px 0 0!important;
	background-color: #ffff;
	padding: 5px 5px 0 5px;
	height: 110px;
}

.p-jin-t {
	text-align: justify;
	font-size: 13px;
	line-height: 15px;
	font-family: ui-monospace;
	letter-spacing: 0.5px;
	padding: 7px;
	border: 5px solid #fff;
	border-radius: 0 0 15px 15px;
	background-color: rgba(240, 255, 240, 0.8);
}
.jin-eng {
	line-height: 16px;
	letter-spacing: 0.5px !important;
	font-size: 16px ;
	font-family: system-ui, serif !important;
}
.jin-rus {
	line-height: 19px;
}

.h-3df {
	padding-bottom: 20px;
}

.huw-cs {
	width: fit-content;
	background-color: #fff;
	padding: 0 20px;
	border-radius: 15px 15px 0 0 !important;
	height: 110px;
}

.huw-css3 {
	width: fit-content;
	border-radius: 15px 15px 0 0 !important;
	background-color: #fff;
}

.card-style {
	padding-left: 0!important;
	padding-right: 0!important;
;
}

.card-partners {
	box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
	transition: all ease 200ms;
	border-radius: 15px;
}


.card-partners:hover {
	transform: scale(1.08);
	box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.f-icon {
	display: block;
	width: 24px;
	height: 18px;
	background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%2013.481c0-2.34.611-4.761%201.833-7.263C3.056%203.716%204.733%201.643%206.865%200L11%202.689C9.726%204.382%208.777%206.093%208.152%207.824c-.624%201.73-.936%203.578-.936%205.545V18H0v-4.519zm13%200c0-2.34.611-4.761%201.833-7.263%201.223-2.502%202.9-4.575%205.032-6.218L24%202.689c-1.274%201.693-2.223%203.404-2.848%205.135-.624%201.73-.936%203.578-.936%205.545V18H13v-4.519z%22%20fill%3D%22%23127a94%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E);
	background-repeat: no-repeat;
}