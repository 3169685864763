


.firstFooterCont{
    margin-top: -3%;
    display: flex;
    padding: 90px 0 20px 66px;
}

$w: 2vmin;
$r: 50%;
$o: .5*(100% + $r) - $r;

@property --k {
    syntax: '<number>';
    initial-value: 0;
    inherits: false
}



.imgSun {
    margin-top:-150px;
    --k: 0;
    --j: calc(1 - var(--k));
    --list: transparent calc(50% - #{$w}), red 0  calc(50% + #{$w}), transparent 0;
    place-self: center;
    object-fit: cover;
    width: 45vmin; height: 45vmin;
    filter: grayScale(var(--j));
    --mask:
            radial-gradient(closest-side, red calc(#{$r} - 1px), transparent #{$r}),
            radial-gradient(closest-side,
                    transparent calc(#{$r} - #{$o} + var(--k)*2*#{$o} - 1px),
                    red calc(#{$r} - #{$o} + var(--k)*2*#{$o}) calc(var(--k)*100% + var(--j)*#{$r} - 1px),
                    transparent calc(var(--k)*100% + var(--j)*#{$r})),
            linear-gradient(var(--list)),
            linear-gradient(90deg, var(--list)),
            linear-gradient(45deg, var(--list)),
            linear-gradient(-45deg, var(--list));
    mask: var(--mask);
    mask-composite: add, intersect, add, add;
    transition: --k .2s ease-out;

    &:hover { --k: 1 }
}

.textStyleFooter > p {
    font-size: 20px;
    line-height: 5px;
}
.textStyleFooter2 {
    margin-right: 6%;
    margin-left: 6%;

}
.textStyleFooterMb > p {
    font-size: 20px;
    line-height: 5px;
}

.drawer.close {
    -webkit-transform: translateX(-95);
    transform: translateX(-95%);
}

.footWrap {
    background-image: url(../../assets/images/footer.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 75%;
    position: absolute;
    background-color: white;
    z-index: 1;
    border-radius: 0 1000px 1000px 0;
}

.drawer {
    //width: 250px;
    //height: 450px;
    //max-width: 300px;
    //padding: 20px 10px;
    box-sizing: border-box;
    //background: #e7e7e7;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform .22s ease-in;
    transition: -webkit-transform .22s ease-in;
    transition: transform .22s ease-in;
    transition: transform .22s ease-in,-webkit-transform .22s ease-in;
    z-index: 90;
}


//Arrow


$ease: cubic-bezier(.2,1,.3,1);

.wrapper {
    //opacity: 0.8%;
    position: absolute;
    bottom: 47%;
    left: 92%;

    svg {
        fill:  rgb(1, 56, 86);
        width: 30px;
        height: auto;
        margin: 0 2rem;
        cursor: pointer;
        overflow: visible!important;
        polygon, path {
            transition: all 0.5s $ease;
        }
        &:hover polygon, &:hover path {
            transition: all 1s $ease;
            fill: #FF4136;
        }

        &:hover .arrow {
            animation: arrow-anim 2.5s $ease infinite;
        }
        &:hover .arrow-fixed {
            animation: arrow-fixed-anim 2.5s $ease infinite;
        }
    }
}

@keyframes arrow-anim {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    5% {
        transform: translateX(-0.1rem);
    }
    100% {
        transform: translateX(1rem);
        opacity: 0;
    }
}

@keyframes arrow-fixed-anim {
    5% {
        opacity: 0;
    }
    20% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}


.scroll-down {
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all .5s ease-in 3s;
    transition: all .5s ease-in 3s;
}

.scroll-down {
    position: absolute;
    bottom: 88px;
    left: 92%;
    margin-left: -16px;
    display: block;
    width: 42px;
    height: 42px;
    border: 2px solid #FFF;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 2;
    -webkit-animation: bounce 2s infinite 2s;
    animation: bounce 2s infinite 2s;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    transform: scale(1)
}
.scroll-down:hover {
    border: 2px solid #FFC355;
}

.scroll-down:before {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
}
.scroll-down:before:hover {
    border: 2px solid #FFC355;
}

.scroll-downSocial {
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all .5s ease-in 3s;
    transition: all .5s ease-in 3s;
}

.scroll-downSocial {
    position: absolute;
    bottom: 135px;
    left: 5.5rem;
    margin-left:-32px;

    background-size: 14px auto;
    border-radius: 50%;
    z-index: 2;
    -webkit-animation: bounce  2.5s infinite  2.5s;
    animation: bounce 2.5s infinite   2.5s;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    transform: scale(1)
}
.typing-textH {
    position: absolute;
    bottom: 100px;
    left: 3.5rem;
    background-color: burlywood;
    opacity: 0.93;
    border-radius: 5px;
    padding-left: 8px;
}

.typing-textH-Our {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 40%);
    line-height: 18px;
    opacity: 0.93;
    border-radius: 5px;
    padding-left: 8px;
}

.pol-a {
    text-align: center;
}

.btn-calc {
    height: fit-content;
    margin:-4px 14px 0px 48px!important;
}

.select-s > .MuiSelect-selectMenu {
    padding: 6px  0 5px 5px ;
}
.select-s > svg {
    width: 15px;
}
.MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 15px!important;
;
}

.typing-textH-cho {
    position: absolute;
    top: 82px;
    line-height: 18px;
    opacity: 0.93;
    border-radius: 5px;
}

.ty-pas {
    width: 369px;
    text-align: center;
    position: relative;
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.col-s-box{
    /* FONT-WEIGHT: 300; */
    width: 420px;
    height: 100px;
    border-radius: 0 20px 20px 0!important;
    background-color: rgba(1,	56	,86, 0.5) !important

}

.col-m-box{
    /* FONT-WEIGHT: 300; */
    width: 420px;
    height: 100px;
    border-radius: 0 20px 20px 0!important;
    background-color: rgba(1,	56	,86, 0.7) !important

}

.arrow_box {
    background-color: rgba(0,0,0, 0.05);
    font-family: 'system-ui', serif;
    text-align: justify;
    border: 1px solid #0c5c87;
    box-shadow: 2px 2px 4px 0px gray;
    border-radius: 6px;
    padding: 16px;
}
.arrow_box:after, .arrow_box:before {
    bottom: 100.3%;
    left: 52%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color:#0c5c87;
    border-width: 17px;
    margin-left: -30px;
}
.arrow_box:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #0c5c87;
    border-width: 36px;
    margin-left: -36px;
}



.arrow_box_sec {
    font-family: 'system-ui', serif;
    text-align: justify;
    border: 1px solid #0c5c87;
    box-shadow: 2px 2px 4px 0px gray;
    border-radius: 6px;
    padding: 16px;
}
.arrow_box_sec:after, .arrow_box:before {
    bottom: 100.3%;
    right: 10%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_sec:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color:#0c5c87;
    border-width: 17px;
    margin-left: -30px;
}
.arrow_box_sec:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #0c5c87;
    border-width: 36px;
    margin-left: -36px;
}

.pulse {
    -webkit-appearance: none;
    position: absolute;
    z-index: -1;
    right: 0%;
    border-radius: 5px;
    width: 420px;
    height: 100px;
    background: white;
    cursor: pointer;
    box-shadow: 1px 0 0 #222;
    animation: pulse 2s infinite;
}
//.dtx:hover{
//    .pulse{
//        -webkit-appearance: none;
//        animation: none;
//    }
//
//}


@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow:  0 0 100px rgba(204,169,44, 0);
    }
    70% {
        -webkit-box-shadow: 0 0 23px 0px #0c5c87;
    }
    100% {
        -webkit-box-shadow:  0 0 0 rgba(204,169,44, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 100px rgba(204,169,44, 0);
        box-shadow: 0 0 23px 0px #0c5c87;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}

.calculator_each ul {
    max-width: 885px;
    margin: 0 auto;
    color: #00536c;
    font-size: 22px;
}
.calculator_each ul li {
    display: flex;
    line-height: 1.5;
    margin: 6px 0;
}

.calculator_each ul li p {
    position: relative;
    flex: 1;
    line-height:2;

}
.calculator_each ul li p:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.2;
    border: 0.7px dashed #00536c;
}
.calculator_each ul li p span {

    display: inline-block;
    padding: 0 3px;
    position: relative;
    z-index: 3;
    font-size: 17px;
}
.calculator_each ul li strong {
    color: #0d5c73;
    display: inline-block;
    float: right;
    padding-left: 21px;
    position: relative;
    font-size: 18px;
}

@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
        transform: translate3d(3000px, 0, 0) scaleX(3);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
        transform: translate3d(-25px, 0, 0) scaleX(1);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
        transform: translate3d(10px, 0, 0) scaleX(0.98);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
        transform: translate3d(-5px, 0, 0) scaleX(0.995);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

.x-ci {
    border: 5px solid white;
    border-bottom: none;
    overflow: hidden;
    background-color: rgb(242, 243, 244);
}

.type-f {
    line-height: 1.3;
    letter-spacing: -0.5px;
    font-size: 18px;
    border-bottom: 2px solid rgb(255, 255, 255);
    border-left: 2px solid rgb(255, 255, 255);
    padding: 11px 0px 11px 11px;
}

.contGoogle > div {
    position: inherit!important;
}

.size-p {
    line-height: 1.3;
    letter-spacing: -0.5px ;
    font-size: 1.4vw;
    border-bottom:2px solid #fff;
    border-left: 2px solid #fff;
    padding: 11px 0 11px 11px;
}

.story-cont {
    width:50%;
    padding: 60px 60px 0 28px;
    text-align: justify;
}

.story-cont-h {
    width:50%;
    text-align: justify;
}

.pic-cont {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    padding: 4px;
    margin: 0px 0px 0px 35px;
    position: relative;
    height: 220px;
    width: 303px;
}

.pic-1 {
    border-radius: 20px;
    padding: 10px;
    position: absolute;
    width: 300px;
}

.pic-2 {
    border-radius: 20px;
    padding: 10px;
    position: absolute;
    width: 300px!important;
}

.carus-cont {
    margin: 45px 15px 0px 43px ;
}

.carus-cont > .carousel-root .carousel {
    border-radius: 5px;
}

.in-wrk {
    top: 492px;
    left: 27px;
    transform: unset;
}

.in-wrks {
    top: 115px;
    left: 50%;
}

.story-s {
    padding: 20px 60px 0 28px;
}

.text-div > p {
    line-height: 17px;
    font-size: 12px;
    margin-top: 20px;
    text-align: justify;
}

.text-div-c > p {
    line-height: 17px;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 15px;
    text-align: justify;
    padding: 15px 42px 9px 13px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
}