@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');


.bgpic {
  background: url(../../assets/images/PowerEnergy.jpg) center/cover ;
  height: 101vh
}



.mondial {
  background-image: url(../../assets/images/calcXY.jpg);
  /* background-repeat: no-repeat; */
  //background-size: cover;
  height: 845px;
  /*background-attachment: fixed;*/
}
.imgCont > img {
  border-radius: 15px;
}

/*// style={{marginRight:-186, marginTop:-50}}*/
.sCase {
  margin-right: -186px;
  margin-top: -50px;
}
.padFor {
  margin-top: 70px!important;
}


.white {
  background-color: white;
  background-size: cover;
  background-attachment: fixed;

}

.textStylePc {
  display:flex;
  margin-left: 21%;
  z-index:1;
}
.bgcont {
  background-image: url(../../assets/images/dad.jpg);
  height: 100%;

}
.bgCalc {
  background-image: url(../../assets/images/calcXYZ.jpg);
  height: 900px;

}

.bgCont {
  background-image: url(../../assets/images/dad3.jpg);
  height: 100%;

}

.tabs {
  width: 100%;
  border-radius: 10px;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  background-color: whitesmoke;
  height: 60px;
  justify-content: space-between;
  display: flex;
}
.tabs>li {
  width: 25%;
  text-align: center;
}
.tabs>li > a.active {
  color: #fff;
  background-color: #949fa8;
}



.tabs > li a {
  display: block;
  color: #708090;
  padding: 12px;
  text-decoration: none;
  font-family: Poppins,Arial,Helvetica Neue,sans-serif;
}
.tabs > li > a:hover {
  color: #fff;
  transition: 250ms;
  background-color: #bfc3c7;

}
.tabs > li {
  font-size: 20px!important;
  cursor: pointer;
  font-weight: bold;
}

.tabs > li > a:not(:hover) {
  transition: 250ms;
}

.navInAbout {
  overflow: hidden;
  width: 100%;
  height: 50px;
  background-color: white;
  margin-top: -39px;
  border-radius: 65px 65px 0 0;
  display: flex;
}


.hover-zoom {
  overflow: hidden;
}

.hover-zoom video {
  transition: all 1s ease 0s;
  width: 100%;
  border-radius: 8px;

}

.hover-zoom video:hover {
  transform: scale(1.04);
  border-radius: 20px;
}


.animated {
  animation-duration: 500ms;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s;
}



.animatedd {
  animation-duration: 1200ms;
  animation-fill-mode: both;
}

.animatedd.infinite {
  animation-iteration-count: infinite;
}

.animatedd.hinge {
  animation-duration: 2s;
}

.animatedd.flipOutX,
.animatedd.flipOutY,
.animatedd.bounceIn,
.animatedd.bounceOut {
  animation-duration: 10.5s;
}

.animateddd {
  animation-duration: 2900ms;
  animation-fill-mode: both;
}

.animateddd.infinite {
  animation-iteration-count: infinite;
}

.animateddd.hinge {
  animation-duration: 5s;
}

.animateddd.flipOutX,
.animateddd.flipOutY,
.animateddd.bounceIn,
.animateddd.bounceOut {
  animation-duration: 10.5s;
}


@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}
/*@keyframes fadeIn {*/
/*  from {*/
/*    opacity: 0;*/
/*  }*/

/*  to {*/
/*    opacity: 1;*/
/*  }*/
/*}*/
/*.fadeIn {*/
/*  -webkit-animation-name: fadeIn;*/
/*  animation-name: fadeIn;*/
/*}*/


/*testimonial-section*/



.containerB {
  z-index: 1;

  align-items: center;
  justify-content: center;
  // min-height: 100vh;

  background-size: cover;
  background-position: center center;
  //padding: 2rem;
}

.xxx:before {
  margin-top: -7px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 2px;
  background-image: url(../../assets/images/flags/Untitled-1.png);
}



.bird {
  opacity: 0.7;
  z-index: 1;
  //background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
  background-image: url(../../assets/images/bird-cells-new.svg);
  background-size: auto 100%;
  width: 43px;
  height: 62px;
  will-change: background-position;

  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;

  &--one {
    animation-duration: 2s;
    animation-delay: -0.5s;
  }

  &--two {
    animation-duration: 1.7s;
    animation-delay: -0.75s;
  }

  &--three {
    animation-duration: 1.25s;
    animation-delay: -0.25s;
  }

  &--four {
    animation-duration: 1.1s;
    animation-delay: -0.5s;
  }

}

.arrType {
  width: 55px;
  position: absolute;
  left: 1rem;
  bottom: 114px;
  will-change: transform;
  animation-name: shake;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-delay: 2s;
}

@keyframes shake {
  50% { transform: translate(1px, -5px) rotate(12deg); }

  100% { transform: translate(0px, 0px) rotate(0deg); }
}


.bird-container {
  position: absolute;
  top: 3%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: 1;

  &--one {
    animation-duration: 12.5s;
    animation-delay: 0s;
  }

  &--two {
    animation-duration: 11.3s;
    animation-delay: 1s;
  }


}

@keyframes fly-cycle {

  100% {
    background-position: -900px 0;
  }

}

@keyframes fly-right-one {

  0% {
    transform: scale(0.3) translateX(-10vw);
  }

  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(4vh) translateX(30vw) scale(0.5);
  }

  30% {
    transform: translateY(6vh) translateX(50vw) scale(0.6);
  }

  40% {
    transform: translateY(8vh) translateX(70vw) scale(0.6);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    transform: translateY(-2vh) translateX(110vw) scale(0.6);
  }

  100% {
    transform: translateY(-2vh) translateX(110vw) scale(0.6);
  }

}

@keyframes fly-right-two {

  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


.d-none {
  opacity: 0;
  cursor: default!important;

}

.instagram {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 4px;
  color: whitesmoke;
  font-size: 22px;
  line-height: 25px;
  vertical-align: middle;

  box-shadow: 0px 3px 10px rgba(0,0,0,.15);
}

.instagram:hover {
  transition: 1s;

  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.we-arm {
  font-weight: 800!important;
}
.we-next {
  font-weight: 500!important;
}

