

.cardsUs {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cardOf {
  background: #fff;
  width: 20em;
  height: 23em;

  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.cardOf:hover {
  transform: scale(1.1);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.cardOf img {
  width: 100%;
  object-fit: cover;
}

.cardOf p {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 350;
  font-family: system-ui, serif;
  transition: all ease-in 100ms;
}

.cardOf h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.card-body {
  padding: 1em;
}
.texE {
  color: rgb(119, 136, 153);
  border: solid 2px whitesmoke;
  border-radius: 13px;
  width: fit-content;
  background-color: white;
  padding: 7px;
}
.jar {
  justify-content: center!important;
}

.jobs > p:hover {
  padding: 3px 0 3px 2px;
  background-color: #ffc355;
  transition: 500ms;
  cursor: pointer;
  border-radius: 5px;
}


.kss {
  border:5.5px solid white;
  padding: 25px;
  width: 900px;
  height: 580px;
  margin: 0 auto;
  border-radius: 110px;
  background-color: whitesmoke;

}
.kss1 {
  border-top:5.5px solid white;
  //padding: 40px;
  height: 600px;
  margin: 0 auto;
  border-radius: 56px;

}

//#hexagon-first-one {
//  z-index: 1;
//  right: 65px;
//
//  width: 100px;
//  height: 57.735px;
//  background: linear-gradient(to bottom , #C0C0C0, #C0C0C0,#fff, #C0C0C0,#808080 );
//  position: relative;
//  opacity: 0.7;
//}
//#hexagon-first-one::before {
//  content: "";
//  position: absolute;
//  top: -28.8675px;
//  left: 0;
//  width: 0;
//  height: 0;
//  border-left: 50px solid transparent;
//  border-right: 50px solid transparent;
//  border-bottom: 28.8675px solid  #C0C0C0;
//}
//#hexagon-first-one::after {
//  content: "";
//  position: absolute;
//  bottom: -28.8675px;
//  left: 0;
//  width: 0;
//  height: 0;
//  border-left: 50px solid transparent;
//  border-right: 50px solid transparent;
//  border-top: 28.8675px solid #808080;
//}


#hexagon-first-one {
  z-index: 1;
  right: 80px;

  width: 100px;
  height: 57.735px;
  background: linear-gradient(to bottom , #C0C0C0, #C0C0C0,#fff, #C0C0C0,#808080 );
  position: relative;
  opacity: 0.6;

}
#hexagon-first-one::before {
  content: "";
  position: absolute;
  top: -28.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 28.8675px solid #C0C0C0;
}
#hexagon-first-one::after {
  content: "";
  position: absolute;
  bottom: -28.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 28.8675px solid #808080;
}

#hexagon-first-two {
  z-index: 1;
  right: 50px;
  width: 95px;
  height: 57.735px;
  background: linear-gradient(to bottom , #C0C0C0, #C0C0C0,#fff, #C0C0C0,#808080 );
  position: relative;
  opacity: 0.6;
}
#hexagon-first-two::before  {
  content: "";
  position: absolute;
  top: -28.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 28.8675px solid #C0C0C0;
}
#hexagon-first-two::after {
  content: "";
  position: absolute;
  bottom: -28.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 28.8675px solid #808080;
}

.hexCircle {
  width: 8px;
  z-index: 1;
  position: absolute;
  left: 6px;
  bottom: 45px;
  opacity: 0.8;
}


.hexCircleMiddle {
  width: 8px;
  z-index: 1;
  position: absolute;
  left: 6px;
  bottom: 23px;
  opacity: 0.8;
}


.hexCircleDown {
  width: 8px;
  z-index: 1;
  position: absolute;
  left: 6px;
  bottom: 3px;
  opacity: 0.8;
}

.left-d {
  left: -9px!important;
}

.hexRight {
  position: absolute;
  z-index: 1;
  right: 131px;
  bottom: 43px;
  width: 8px;
  opacity: 0.8;
}
.hexRightMiddle {
  position: absolute;
  z-index: 1;
  right: 131px;
  bottom: 22px;
  width: 8px;
  opacity: 0.8;
}
.hexRightDown {
  position: absolute;
  z-index: 1;
  right: 131px;
  bottom: 2px;
  width: 8px;
  opacity: 0.8;
}


#base {
background: red;
display: inline-block;
height: 55px;
margin-left: 20px;
margin-top: 55px;
position: relative;
width: 100px;
}
#base:before {
border-bottom: 35px solid red;
border-left: 50px solid transparent;
border-right: 50px solid transparent;
content: "";
height: 0;
left: 0;
position: absolute;
top: -35px;
width: 0;
}



@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateBack {
  to {
    transform: rotate(-360deg);
  }
}

.rotating {
  animation: rotate 19.5s linear infinite;
}
.rotatingBack {
  animation: rotateBack 16s linear infinite;
}
.rotating2 {
  animation: rotate 22.5s linear infinite;
}